import React from "react";

const Welcome = ({ currentUser }) => {
  return (
    <div className="welcome">
      <h3>Welcome {currentUser}!</h3>
      <p>Select a contact to start a conversation...</p>
    </div>
  );
};

export default Welcome;
