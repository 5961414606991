//import { isDocument } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useRef } from "react";
import url from "../context/creds";
import { useSpeechSynthesis } from "react-speech-kit";

const Messages = ({ messages, currentUser,selectedChat,onlineusers}) => {
  const scrollRef = useRef(null);
  const { speak } = useSpeechSynthesis();
  
// console.log("From Messages",selectedChat)
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    hour12: true,
    timeZone: 'IST'
  });
};
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView(false);
  }, [selectedChat]);

  const filteredMessages = messages.filter(
    (msg) =>
      (msg.from === currentUser && msg.to === selectedChat.username) ||
      (msg.to === currentUser && msg.from === selectedChat.username)
  );
  
  return (

//     <div className="messages" ref={scrollRef}>
//     {messages
//       .filter((msg) =>
//       (msg.from === currentUser && msg.to === messages.username) ||
//       (msg.to === currentUser && msg.from === messages.username)
//       )
//       .map((msg, index) => (
//       <Message key={index} msg={msg} currentUser={currentUser} />
//       )
//       )
//     }
//   </div>
<div className="messages">
    {filteredMessages.map((msg, index) => (
      <div
        ref={scrollRef}
        key={index}
        className={`message ${msg.from === currentUser ? "owner" : ""}`}>
        {msg.isDocument.filename === false || msg.isDocument === undefined ? (

  <div className="messageContent" onClick={() => speak({ text: (msg.from === currentUser ? msg.fromMessage : msg.toMessage) }) }>
    <p>{msg.from === currentUser && msg.to === selectedChat.username ? msg.fromMessage : msg.toMessage}</p>
    <div className="metadata">
      <span className="timestamp">{formatDate(msg.timestamp)}</span><br></br>
      {msg.from === currentUser && (
        <span className={`msg-status ${msg.from === currentUser ? 'owner' : ''}`}>
          {formatDate(selectedChat.lastseen) < formatDate(msg.timestamp) ? 'Sent' : 'Seen'}
        </span>
      )}
    </div>
  </div>
) : (
  <div className="messageContentDoc">
    <p>{msg.isDocument.originalFilename}
    <a href={url+`file/${msg.isDocument.filename}`} target="_self" className="downloadLink">
        <img src={require('../images/download.jpg')} alt="Download" className="downloadIcon" />
      </a>
    </p>
    <div className="metadata">
      <span className="timestamp">{formatDate(msg.timestamp)}</span><br></br>
      {msg.from === currentUser && (
        <span className={`msg-status ${msg.from === currentUser ? 'owner' : ''}`}>
          {formatDate(selectedChat.lastseen) < formatDate(msg.timestamp) ? 'Sent' : 'Seen'}
        </span>
      )}
    </div>
  </div>
)}
 

      </div>
    ))}
  </div>
  );
};

export default Messages;