import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import io from 'socket.io-client';
import Login from './pages/login';
import Home from './pages/home';
import './style.scss'
import axios from 'axios';
import url from './context/creds'
import Register from './pages/register';
//import AudioCall from './components/audioCall';
const App = () => {
  const [socket, setSocket] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [currentUser, setCurrentUser] = useState(sessionStorage.getItem('curruser') || '');
  const [selectedChat, setSelectedChat] = useState( JSON.parse(localStorage.getItem('selectedChat')) || null);
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);
  const [users, setUsers] = useState([]);
  const [messages, setMessages] = useState(JSON.parse(localStorage.getItem('messages')) || []);
  const [userlang, setUserlang] = useState('');
  
  

  console.log("App");



  useEffect(() => {
    const newSocket = io(url, {
      //transports: ['websocket'],
      extraHeaders: {
        'ngrok-skip-browser-warning': '69420'
      }
    });
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to socket server');
    });
    newSocket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    if (true) {
      
      axios.get(url+'users',{
       extraHeaders: {
          "ngrok-skip-browser-warning": "69420"
        }
      })
        .then(response => {
          console.log(response)
          setUsers(response.data.filter(user => user.username !== currentUser));
        
          const currentUserData = response.data.find(user => user.username === currentUser);
            if (currentUserData) {
              setUserlang(currentUserData.language);
              //console.log(userlang)
            }
          })
        
        .catch(error => {
          console.error('Error fetching users', error);
        });
    }
  }, [true, currentUser, setUsers]);

  useEffect(() => {
    localStorage.setItem('messages',JSON.stringify(messages));
  }, [messages]);


  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    setToken('');
    setCurrentUser('');
    setSelectedChat(null);
    setIsLoggedIn(false);
    //navigate('/');
    //console.log("test");
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login socket={socket} setToken={setToken} setCurrentUser={setCurrentUser} setIsLoggedIn={setIsLoggedIn} setMessages={setMessages} />} />
        <Route path="/home" element={<Home socket={socket} token={token} currentUser={currentUser} users={users} selectedChat={selectedChat} setSelectedChat={setSelectedChat} setUsers={setUsers} messages={messages} setMessages={setMessages} isLoggedIn={isLoggedIn} userlang={userlang} handleLogOut={handleLogOut}/>} />
        <Route path="/register" element={<Register socket={socket} token={token} currentUser={currentUser} users={users} setUsers={setUsers} messages={messages} setMessages={setMessages} isLoggedIn={isLoggedIn} userlang={userlang} />} />
      </Routes>
    </Router>
  );
};

export default App;
