import React, { useState } from "react";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import axios from "axios";
import url from "../context/creds";
//import Picker from "emoji-picker-react";
const Input = ({ message, setMessage, setShowPicker, sendMessage, userlang }) => {
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [selectedLanguage, setSelectedLanguage] = useState(userlang); // Default language is userlang
  const [isListening, setIsListening] = useState(false);
  const [file, setFile] = useState(null); // State to manage the file
  //const [showPicker, setShowPicker] = useState(false);
  //const popUpRef = useRef(null);


  const handleSendMessage = () => {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      axios.post(url+"upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => {
          console.log("File uploaded successfully:", res.data.file);
          sendMessage(selectedLanguage,res.data.file.filename,res.data.file.originalname);
        })
        .catch((error) => {
          console.error("Error uploading file:", error);
        });
        setFile(null)
    } else {
      sendMessage(selectedLanguage,false,null);
      setShowPicker(false);
    }
  };

  const handleMouseDown = async () => {
    setIsListening(true);
    resetTranscript();
    console.log("Listening started in language:", selectedLanguage);
    await SpeechRecognition.startListening({ continuous: true, language: selectedLanguage });
  };

  const handleMouseUp = async () => {
    setIsListening(false);
    await SpeechRecognition.stopListening();
    console.log("Listening stopped. Transcript:", transcript);
    setMessage(transcript);
    handleSendMessage();
  };

  const handleFileChange = (e) => {
    setMessage(e.target.files[0].name)
    setFile(e.target.files[0]);
  };

  return (
    <div className="input">
      <img
        className="emojiIcon"
        src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg" alt=""
        onClick={() => setShowPicker((val) => !val) }
      />
      <input
        type="text"
        placeholder="Type a message"
        value={message}
        onChange={(e) => { setMessage(e.target.value ) }}
        onKeyPress={(e) => e.key === "Enter" && handleSendMessage()}
      />
      <select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
        <option value="en">English</option>       {/*English */}
        <option value="bn">বাংলা</option>          {/*Bengali */}
        <option value="gu">ગુજરાતી</option>        {/*Gujrati */}
        <option value="hi">हिन्दी</option>          {/*Hindi */}
        <option value="kn">ಕನ್ನಡ</option>         {/*Kannada */}
        <option value="ml">മലയാളം</option>     {/*Malayalam */}
        <option value="mr">मराठी</option>          {/*Marathi */}
        <option value="pa">ਪੰਜਾਬੀ</option>          {/*Punjabi */}
        <option value="ta">தமிழ்</option>         {/*Tamil */}
        <option value="te">తెలుగు</option>         {/*Telugu */}
        <option value="ur">اردو</option>           {/*Urdu */}
      </select>
      <div className="send">
        <img
          src={require("../images/mic.png")}
          alt="mic"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onTouchStart={handleMouseDown}
          onTouchEnd={handleMouseUp}
        />
        <input type="file" style={{ display: "none" }} id="file" onChange={handleFileChange} />
        <label htmlFor="file">
          <img src={require("../images/Camera.png")} alt="camera" />
        </label>
      </div>
    </div>
  );
};

export default Input;
