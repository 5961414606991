import React, { useRef } from "react";
import Picker from "emoji-picker-react";


const Emojis = ({message, setMessage, showPicker, setShowPicker}) => {
    
    const popUpRef = useRef(null);

    const onEmojiClick = (event, emojiObject) => {
        setMessage(message + event.emoji);
      };


    return(
        <div className="emojiPopUp" ref={popUpRef}>
            {showPicker && (
                <Picker style={{ width: "100"  }} onEmojiClick={onEmojiClick} />
            )}
        </div>
    );
}

export default Emojis;