import React from "react";


const Presentation = ({ displayPresent, setDisplayPresent, bigPresentationRef, setBigVideo, setSmallVideo }) => {

    const removeSelection = () => {
        if (!bigPresentationRef){
            bigPresentationRef = null;
        }
        setDisplayPresent(false);
    };

    return (
        <div className="present">
            <div className="main">
                <button style={{float: "right"}} onClick={() => removeSelection()} >X</button>
                { (bigPresentationRef) ? (<><video ref={bigPresentationRef} autoPlay style={{alignItems: "center"}} ></video></>) : null }
            </div>
            <div className="options">
                <p>Option box</p>
            </div>
        </div>
    );
}

export default Presentation;