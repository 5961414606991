import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../components/sidebar";
import Chat from "../components/chat";
import Welcome from "../components/welcome";
import User from "../components/user";
//import { isDocument } from "@testing-library/user-event/dist/utils";


const Home = ({ socket, token, currentUser, users, selectedChat, setSelectedChat, setUsers, messages, setMessages, isLoggedIn, userlang, handleLogOut }) => {
  
  const [message, setMessage] = useState('');
  const [showPicker, setShowPicker] = useState(false);
  const [isCall, setIsCall] = useState(false);
  const [displayBox, setDisplayBox] = useState(false);
  const [remotePeerId, setRemotePeerId] = useState('');
  const [remoteVideoPeerId, setRemoteVideoPeerId] = useState('');
  const [isRunning, setIsRunning] = useState(false);
  const [isVideoCall, setIsVideoCall] = useState(false);
  const [displayPresent, setDisplayPresent] = useState(false);
  const [bigVideo, setBigVideo] = useState(false);
  const [smallVideo, setSmallVideo] = useState(false);
  const bigPresentationRef = useRef(null);

  console.log("Home");

  useEffect(() => {
    if (socket) {
      socket.on('message', (msg) => {
        setMessages((prevMessages) => [...prevMessages, msg]);
      });

      return () => {
        socket.off('message');
      };
    }
  }, [token, socket]);

  useEffect(() => {
    if(socket){
      socket.on('receiveAudioPeerId', (audioData) => {
      setRemotePeerId(audioData.peerid);
      console.log("Remote Peer Id: ", remotePeerId);
      console.log("From: ", audioData.from);
      //setReceivedPeerId(true);

      if (remotePeerId !== '' || remotePeerId !== undefined){
        setIsCall(true);
        setDisplayBox(true);
      }
      else{
        setIsCall(false);
        setDisplayBox(false);
      }
      });

      return () => {
        socket.off('receiveAudioPeerId');
      };
    }
  },[socket, remotePeerId]);

  useEffect(() => {
    if(socket){
      socket.on('receiveVideoPeerId', (videoData) => {
      setRemoteVideoPeerId(videoData.videopeerid);
      console.log("Remote Peer Id: ", remoteVideoPeerId);
      console.log("From: ", videoData.from);
      //setReceivedPeerId(true);

      if (remoteVideoPeerId !== '' || remoteVideoPeerId !== undefined){
        setIsVideoCall(true);
        setDisplayBox(true);
      }
      else{
        setIsVideoCall(false);
        setDisplayBox(false);
      }
      });

      return () => {
        socket.off('receiveVideoPeerId');
      };
    }
  },[socket, remoteVideoPeerId]);

  useEffect(() => {
    localStorage.setItem('selectedChat', JSON.stringify(selectedChat));
  }, [selectedChat]);

  const selectChat = (user) => {
    setSelectedChat(user);
  };

  const closeChat = () => {
    setSelectedChat(null);
    setMessage('');
  };


  const sendMessage = (userlang,doc_flag,origName) => {
    if (currentUser && selectedChat && message) {
      console.log(message)
      const newMessage = { from: currentUser, to: selectedChat.username, fromMessage: message,preflang:userlang,doc_flag:doc_flag,originalFilename:origName};
      socket.emit('sendMessage', newMessage);
      //setMessages((prevMessages) => [...prevMessages, newMessage]);
      // console.log(messages)
      setMessage('');
    }
  };


  return (
    <div className="home">
      <div className="container">
        <Sidebar selectChat={selectChat} selectedChat={selectedChat} users={users} currUser={currentUser} handleLogOut={handleLogOut}/>
        { (selectedChat && displayBox) ? (
          <>
          <Chat
            currentUser={currentUser}
            selectedChat={selectedChat}
            messages={messages}
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
            closeChat={closeChat}
            userlang={userlang}
            displayPresent={displayPresent}
            setDisplayPresent={setDisplayPresent}
            displayBox={displayBox}
            setDisplayBox={setDisplayBox}
            isCall={isCall}
            setIsCall={setIsCall}
            isVideoCall={isVideoCall}
            setIsVideoCall={setIsVideoCall}
            socket={socket}
            bigPresentationRef={bigPresentationRef}
            setBigVideo={setBigVideo}
            setSmallVideo={setSmallVideo}
          /> 
          <User socket={socket} currentUser={currentUser} selectedChat={selectedChat} displayBox={displayBox} setDisplayBox={setDisplayBox} isCall={isCall} setIsCall={setIsCall} isVideoCall={isVideoCall} setIsVideoCall={setIsVideoCall} audioData videoData remotePeerId={remotePeerId} setRemotePeerId={setRemotePeerId} remoteVideoPeerId={remoteVideoPeerId} setRemoteVideoPeerId={setRemoteVideoPeerId} isRunning={isRunning} setIsRunning={setIsRunning} displayPresent={displayPresent} setDisplayPresent={setDisplayPresent} bigPresentationRef={bigPresentationRef} bigVideo={bigVideo} setBigVideo={setBigVideo} setSmallVideo={setSmallVideo} />
          </>
          ) : (selectedChat) ? (
          <>
            <Chat
            currentUser={currentUser}
            selectedChat={selectedChat}
            messages={messages}
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
            showPicker={showPicker}
            setShowPicker={setShowPicker}
            closeChat={closeChat}
            userlang={userlang}
            displayPresent={displayPresent}
            setDisplayPresent={setDisplayPresent}
            displayBox={displayBox}
            setDisplayBox={setDisplayBox}
            isCall={isCall}
            setIsCall={setIsCall}
            isVideoCall={isVideoCall}
            setIsVideoCall={setIsVideoCall}
            socket={socket}
            bigPresentationRefPresentationRef={bigPresentationRef}
            setBigVideo={setBigVideo}
            setSmallVideo={setSmallVideo}
            />
          </>
        ) : (
          <Welcome currentUser={currentUser} />
          )
        }
        
      </div>
    </div>
  );
};

export default Home;
