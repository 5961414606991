import React, { useState, useMemo } from "react";

const Search = ({ selectChat, selectedChat, users }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = useMemo(() => {
    return users.filter(user => user.username.toLowerCase().includes(searchTerm.toLowerCase())
  )},[users, searchTerm]);


  return (
    <div className="search">
      <div className="searchForm">
        <input type="text" placeholder="Find a user" value={searchTerm} onChange={handleSearchChange}/>
        <div className="chats">
          {filteredUsers.map((user) => (
            <div key={user.username} className={`userChat ${ (selectedChat) ? (user.username === selectedChat.username ? "selected" : "") : ("")}`} onClick={() => selectChat(user)}>
              <img src={require("../images/User Image.png")} alt="" />
              <div className="userChatInfo">
                <span>{user.username}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
