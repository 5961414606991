import React, { useState, useEffect, useRef, useCallback } from 'react';
import Peer from 'peerjs';
import Timer from './timer';

const VideoCall = ({ socket, currentUser, selectedChat, displayBox, setDisplayBox, isVideoCall, setIsVideoCall, videoData, remoteVideoPeerId, setRemoteVideoPeerId, isRunning, setIsRunning, displayPresent, setDisplayPresent, bigPresentationRef, bigVideo, setBigVideo, setSmallVideo }) => {
  const [peer, setPeer] = useState(null);
  const [videoCall, setVideoCall] = useState(null);
  const [videoPeerId, setVideoPeerId] = useState('');
  const [incomingCall, setIncomingCall] = useState(null);
  const [userCalled, setUserCalled] = useState('');
  const [callEnding, setCallEnding] = useState(false);
  const [remoteStream, setRemoteStream] = useState(null);
  const [localStream, setLocalStream] = useState(null);
  const [presentStreamState, setPresentStreamState] = useState('');

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const localStreamRef = useRef(null);

  console.log("VideoCall");
  console.log("Video Peer ID: ", videoPeerId);
  console.log("Remote Video Peer ID: ", remoteVideoPeerId);

  const showPresentationScreen = () => {
    if(!displayPresent){
      setDisplayPresent(true);
      console.log("Presentation Box opened");
    }
  };

  const removePresentationScreen = () => {
    if (displayPresent){
      setDisplayPresent(false);
      console.log("Presentation Box closed");
    }
  };

  const setStream = useCallback((videoRef, stream) => {
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
  }, []);  
  
  useEffect(() => {
    setUserCalled(videoData.username);
  }, [videoData.username]);

  useEffect(() => {
    const newPeer = new Peer();
    setPeer(newPeer);

    if (!remoteVideoPeerId) {
      newPeer.on('open', id => {
        console.log("Creating Video Peer Id and sending it");
        setVideoPeerId(id);
        socket.emit('sendVideoPeerId', { fromusername: currentUser, username: selectedChat.username, videopeerid: id });
        setCallEnding(false);


      });
    } else {
      setVideoPeerId(remoteVideoPeerId);
      setCallEnding(false);  

    }

    newPeer.on('call', incomingCall => {
      setIncomingCall(incomingCall);
      console.log("Incoming call from: ", incomingCall.peer);
    });

    return () => {
      newPeer.destroy();
      setVideoPeerId('');
      console.log("Destroying peer connection");
    };
  }, [socket, userCalled, remoteVideoPeerId]);

  useEffect(() => {
    if (videoCall) {
      setIsRunning(true);
      videoCall.on('close', () => {
        handleCallEnded();
      });
    }
  }, [videoCall]);

  useEffect(() => {
    if(incomingCall){
      answerCall();
    }
  }, [incomingCall]);

  useEffect(() => {
    socket.on('videoCallEnded', () => {
      console.log("Call ended received via socket!");
      handleCallEnded();
    });

    return () => {
      socket.off('videoCallEnded');
    };
  }, [socket]);


  const handleCallEnded = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => track.stop());
    }
    
    setLocalStream(null);
    setRemoteStream(null);
    setPresentStreamState('');
    setIsRunning(false);
    setStream(localVideoRef, null);
    //localVideoRef.current.srcObject = null;
    setStream(remoteVideoRef, null);
    //remoteVideoRef.current.srcObject = null;
    setStream(bigPresentationRef, null);
    //bigPresentationRef.current.srcObject = null;
    setVideoPeerId('');
    setRemoteVideoPeerId('');
    setCallEnding(true);
    setTimeout(() => {
      setVideoCall(null);
      setIsVideoCall(false);
      console.log("Video Call ended");      
      removePresentationScreen();
      console.log("Closing Presentation Screen");
    }, 1000);

    setTimeout(() => {
      setDisplayBox(false);
      console.log("Closing Display Box");
    }, 1000); 
  };

  const answerCall = async () => {
    console.log("Answering incoming Video call");
    //setIsRunning(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    setLocalStream(stream);
    localVideoRef.current.srcObject = stream;
    localStreamRef.current = stream;

    incomingCall.answer(stream);
    incomingCall.on('stream', remoteStream => {
      setRemoteStream(remoteStream);
      //setVideoStream(remoteStream);
      setStream(remoteVideoRef, remoteStream);
      //remoteVideoRef.current.srcObject = remoteStream;
      //bigPresentationRef.current.srcObject = remoteStream;
    });

    incomingCall.on('close', () => {
      handleCallEnded();
      socket.emit('callEnded', { to: userCalled });
    });

    setVideoCall(incomingCall);
    setIsVideoCall(true);
    setIncomingCall(null);  // Clear incoming call state after answering
    console.log("Video Call: ", videoCall);
    console.log("Answering Video call function");
  };

  const startCall = async (peerId) => {
    console.log("Starting call with: ", peerId);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    setLocalStream(stream);
    localVideoRef.current.srcObject = stream;
    localStreamRef.current = stream;

    const outgoingCall = peer.call(peerId, stream);
    outgoingCall.on('stream', remoteStream => {
      setRemoteStream(remoteStream);
      setStream(remoteVideoRef, remoteStream);
      //remoteVideoRef.current.srcObject = remoteStream;
      //bigPresentationRef.current.srcObject = remoteStream;
    });

    outgoingCall.on('close', () => {
      handleCallEnded();
      socket.emit('callEnded', { to: userCalled /*selectedChat.username*/ });
    });

    setVideoCall(outgoingCall);
    setIsVideoCall(true);
    console.log("Start call function");
  };

  const endCall = () => {
    if (videoCall) {
      videoCall.close();
    }

    socket.emit('callEnded', { to: userCalled });

    handleCallEnded();
    console.log("End Call function");
  };

  const rejectCall = () => {
    if (incomingCall) {
      incomingCall.close();
      console.log("Rejecting incoming call");
    }
    setIncomingCall(null);

    socket.emit('callEnded', { to: userCalled });

    handleCallEnded();
  };

  return (
    <div className='videoCall'>
      <div>
        { (bigPresentationRef) ? 
        ( presentStreamState === "local" ) ? 
        (
          <video ref={remoteVideoRef} autoPlay className='remoteVideo' display={!callEnding} onClick={() => {setStream(bigPresentationRef,remoteStream); setPresentStreamState("remote"); showPresentationScreen(); }} />
        ) : (
          <video ref={localVideoRef} autoPlay muted className='localVideo' display={!callEnding} onClick={() => {setStream(bigPresentationRef,localStream); setPresentStreamState("remote"); showPresentationScreen(); }} />
        ) : (
        <><video ref={localVideoRef} autoPlay muted className='localVideo' display={!callEnding} onClick={() => {setStream(bigPresentationRef,localStream); setPresentStreamState("remote"); showPresentationScreen(); }} />
        <video ref={remoteVideoRef} autoPlay className='remoteVideo' display={!callEnding} onClick={() => {setStream(bigPresentationRef,remoteStream); setPresentStreamState("remote"); showPresentationScreen(); }} /> </>)
        }
      </div>
      {incomingCall ? (
        <>
          <h3>Incoming Video call</h3>
          <div className='callButton'>
          <button onClick={answerCall}>Accept</button>
          <button onClick={rejectCall}>Reject</button>
          </div>
        </>
      ) : videoCall ? (
        <>
          { (callEnding) ? <h3>Video Call Ended</h3> : <></> }
          <div><Timer isRunning={isRunning} setIsRunning={setIsRunning} /></div>
          { (callEnding) ? (<></>) : (<div className='callButton'><button className='callButton' onClick={endCall} disabled={callEnding}>End Call</button></div>)}
        </>
      ) : (videoPeerId === remoteVideoPeerId && videoPeerId !== '') ? (
        <>
          <h3>Incoming Video call</h3>
          <div className='callButton'>
          <button className='callButton' onClick={() => startCall(videoPeerId)}>Accept</button>
          <button className='callButton' onClick={endCall} >Reject</button>
          </div>
        </>
      ) : (
        <>
          <h3>Connecting Video call...</h3>
          <div className='callButton'><button className='callButton' onClick={endCall} disabled={videoCall}>End Call</button></div>
        </>
      )}
    </div>
  );
};

export default VideoCall;