import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import url from '../context/creds'
const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [language, setLanguage] = useState('en');
  const navigate = useNavigate();

  const signUp = () => {
    axios.post(url+'register', { username, password, language })
      .then(response => {
        alert('User registered successfully');
        navigate("/");
      })
      .catch(error => {
        alert('Error registering user');
      });
  };

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">VNN Chat</span>
        <span className="title">Register</span>
        <form>
          <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <select value={language} onChange={(e) => setLanguage(e.target.value)}>
            <option value="en">English</option>
            <option value="bn">Bengali (বাংলা)</option>
            <option value="gu">Gujarati (ગુજરાતી)</option>
            <option value="hi">Hindi (हिन्दी)</option>
            <option value="kn">Kannada (ಕನ್ನಡ)</option>
            <option value="ml">Malayalam (മലയാളം)</option>
            <option value="mr">Marathi (मराठी)</option>
            <option value="pa">Punjabi (ਪੰਜਾਬੀ)</option>
            <option value="ta">Tamil (தமிழ்)</option>
            <option value="te">Telugu (తెలుగు)</option>
            <option value="ur">Urdu (اردو)</option>
          </select>
          <button type="button" onClick={signUp}>Sign Up</button>
        </form>
        <p>Do you have an account? <Link to="/">Log In</Link></p>
      </div>
    </div>
  );
};

export default Register;
