import React, { useState, useEffect, useRef } from 'react';
import Peer from 'peerjs';
import Timer from './timer';

const AudioCall = ({ socket, currentUser, selectedChat, displayBox, setDisplayBox, isCall, setIsCall, audioData, remotePeerId, setRemotePeerId, isRunning, setIsRunning }) => {
  const [peer, setPeer] = useState(null);
  const [call, setCall] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [peerId, setPeerId] = useState('');
  const [incomingCall, setIncomingCall] = useState(null);
  const [userCalled, setUserCalled] = useState('');
  const [callEnding, setCallEnding] = useState(false);

  const localAudioRef = useRef();
  const remoteAudioRef = useRef();
  const localStreamRef = useRef(null);

  console.log("AudioCall");
  console.log("Peer ID: ", peerId);
  console.log("Remote Peer ID: ", remotePeerId);

  
  useEffect(() => {
    setUserCalled(audioData.username);
  }, [audioData.username]);

  useEffect(() => {
    const newPeer = new Peer();
    setPeer(newPeer);

    if (!remotePeerId) {
      newPeer.on('open', id => {
        console.log("Creating Peer Id and sending it");
        setPeerId(id);
        socket.emit('sendAudioPeerId', { fromusername: currentUser, username: selectedChat.username, peerid: id });
        setCallEnding(false);

        /*setTimeout(() => {
          if (!call) {
            console.log("Call: ", call);
            console.log("No call established within 20 seconds, ending call.");
            handleCallEnded();
          }
        }, 20000); */

      });
    } else {
      setPeerId(remotePeerId);
      setCallEnding(false);  

      /*setTimeout(() => {
        if (!call) {
          console.log("Call: ", call);
          console.log("No call established within 20 seconds, ending call.");
          handleCallEnded();
        }
      }, 20000); */

    }

    newPeer.on('call', incomingCall => {
      setIncomingCall(incomingCall);
      console.log("Incoming call from: ", incomingCall.peer);
    });

    return () => {
      newPeer.destroy();
      setPeerId('');
      console.log("Destroying peer connection");
    };
  }, [socket, userCalled, remotePeerId]);

  useEffect(() => {
    if (call) {
      setIsRunning(true);
      call.on('close', () => {
        handleCallEnded();
      });
    }
  }, [call]);

  useEffect(() => {
    if(incomingCall){
      answerCall();
    }
  }, [incomingCall]);

  useEffect(() => {
    socket.on('audioCallEnded', () => {
      console.log("Call ended received via socket!");
      handleCallEnded();
    });

    return () => {
      socket.off('audioCallEnded');
    };
  }, [socket]);


  const handleCallEnded = () => {
    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => track.stop());
    }
    
    setRemoteStream(null);
    setIsRunning(false);
    localAudioRef.current.srcObject = null;
    remoteAudioRef.current.srcObject = null;
    setPeerId('');
    setRemotePeerId('');
    setCallEnding(true);
    setTimeout(() => {
      console.log("This message is delayed by 1 second.");
      setCall(null);
      setIsCall(false);
      console.log("Call ended");
    }, 1000); // 1000 milliseconds = 1 second
    
    setTimeout(() => {
      setDisplayBox(false);
      console.log("Closing Display Box");
    }, 1000); 
  };

  const answerCall = async () => {
    console.log("Answering incoming call");
    //setIsRunning(true);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    localAudioRef.current.srcObject = stream;
    localStreamRef.current = stream;

    incomingCall.answer(stream);
    incomingCall.on('stream', remoteStream => {
      setRemoteStream(remoteStream);
      remoteAudioRef.current.srcObject = remoteStream;
    });

    incomingCall.on('close', () => {
      handleCallEnded();
      socket.emit('audioCallEnded', { to: userCalled });
    });

    setCall(incomingCall);
    setIsCall(true);
    setIncomingCall(null);  // Clear incoming call state after answering
    console.log("Call: ", call);
    console.log("Answering call function");
  };

  const startCall = async (peerId) => {
    console.log("Starting call with: ", peerId);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    localAudioRef.current.srcObject = stream;
    localStreamRef.current = stream;

    const outgoingCall = peer.call(peerId, stream);
    outgoingCall.on('stream', remoteStream => {
      setRemoteStream(remoteStream);
      remoteAudioRef.current.srcObject = remoteStream;
    });

    outgoingCall.on('close', () => {
      handleCallEnded();
      socket.emit('callEnded', { to: userCalled /*selectedChat.username*/ });
    });

    setCall(outgoingCall);
    setIsCall(true);
    console.log("Start call function");
  };

  const endCall = () => {
    if (call) {
      call.close();
    }

    socket.emit('audioCallEnded', { to: userCalled });

    handleCallEnded();
    console.log("End Call function");
  };

  const rejectCall = () => {
    if (incomingCall) {
      incomingCall.close();
      console.log("Rejecting incoming call");
    }
    setIncomingCall(null);

    socket.emit('audioCallEnded', { to: userCalled });

    handleCallEnded();
  };

  return (
    <div className='call'>
      <div>
        <audio ref={localAudioRef} autoPlay muted />
        <audio ref={remoteAudioRef} autoPlay />
      </div>
      {incomingCall ? (
        <>
          <h3>Incoming call</h3>
          <div className='callButton'>
          <button onClick={answerCall}>Accept</button>
          <button onClick={rejectCall}>Reject</button>
          </div>
        </>
      ) : call ? (
        <>
          { (callEnding) ? <h3>Call Ended</h3> : <h3>Call connected</h3> }
          <div><Timer isRunning={isRunning} setIsRunning={setIsRunning} /></div>
          <div className='callButton'><button className='callButton' onClick={endCall} disabled={callEnding}>End Call</button></div>
        </>
      ) : (peerId === remotePeerId && peerId !== '') ? (
        <>
          <h3>Incoming call</h3>
          <div className='callButton'>
          <button className='callButton' onClick={() => startCall(peerId)}>Accept</button>
          <button className='callButton' onClick={endCall} >Reject</button>
          </div>
        </>
      ) : (
        <>
          <h3>Connecting call...</h3>
          <div className='callButton'><button className='callButton' onClick={endCall} disabled={call}>End Call</button></div>
        </>
      )}
    </div>
  );
};

export default AudioCall;