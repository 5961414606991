import React from "react";
import { useNavigate } from "react-router-dom";

const Navbar = ({ currentUser, handleLogOut }) => {
  const navigate = useNavigate();
  //const [selectChat, setSelectedChat] = useState( JSON.parse(localStorage.getItem('selectedChat')) || null);

  /*const logOut = () => {
    handleLogOut();
    //navigate('/');
  }*/

  const exit = () => {
    handleLogOut();
    navigate("/")
  }


  return (
    <div className="navbar">
      <span className="logo">VNN Chat</span>
      <div className="user">
        <img src={require("../images/Vaibhav.jpeg")} alt="" />
        {/* <span>{currentUser}</span> */}
        <button onClick={exit}>Logout</button>
      </div>
    </div>
  );
};

export default Navbar;
