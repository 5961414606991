import AudioCall from "./audioCall";
import { PROFILE } from "../data/profile";
import { useRef, useState } from "react";
import VideoCall from "./videoCall";

const User = ({ socket, currentUser, selectedChat, displayBox, setDisplayBox, isCall, setIsCall, isVideoCall, setIsVideoCall, audioData, videoData, remotePeerId, setRemotePeerId, remoteVideoPeerId, setRemoteVideoPeerId, isRunning, setIsRunning, displayPresent, setDisplayPresent, bigPresentationRef, bigVideo, setBigVideo, setSmallVideo }) => {

  //const [closeStatus, setCloseStatus] = useState(false);
  console.log("user");

  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);

  const handleMouseDown = (e) => {
    setDragging(true);
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (e) => {
    if (dragging) {
      const rect = imageRef.current.getBoundingClientRect();
      const newX = Math.min(0, Math.max(e.clientX - rect.width / 2, window.innerWidth - rect.width));
      const newY = Math.min(0, Math.max(e.clientY - rect.height / 2, window.innerHeight - rect.height));
      setPosition({ x: newX, y: newY });
    }
  };



  return (
    
    <div className="profile">
      <ul>  
        {PROFILE.map((item) => (
          <li key={item.id}>
          <div className="head">
            { (isCall) ? (<h3>Audio Call</h3>) : (isVideoCall) ? (<h3>Video Call</h3>) : (<h3>User Profile</h3>) }
            <button onClick={() => {setDisplayBox(false); setIsCall(false); setIsVideoCall(false); }}>X</button>
          </div>
          <div className="body">
            <div className="image-frame" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} >
              <img ref={imageRef} className="draggable-image" src={item.image} alt={item.name} style={{ transform: `translate(${position.x}px, ${position.y}px)` }}/>
            </div>
            <h3>{item.name}</h3>
            <p><b>Phone: </b>{item.phone}</p>
          </div>
          <div className="foot">
          { isCall ? 
            (<AudioCall socket={socket} currentUser={currentUser} selectedChat={selectedChat} displayBox={displayBox} setDisplayBox={setDisplayBox} isCall={isCall} setIsCall={setIsCall} audioData remotePeerId={remotePeerId} setRemotePeerId={setRemotePeerId} isRunning={isRunning} setIsRunning={setIsRunning} />
            ) : (isVideoCall) ? (
              <VideoCall socket={socket} currentUser={currentUser} selectedChat={selectedChat} displayBox={displayBox} setDisplayBox={setDisplayBox} isVideoCall={isVideoCall} setIsVideoCall={setIsVideoCall} videoData remoteVideoPeerId={remoteVideoPeerId} setRemoteVideoPeerId={setRemoteVideoPeerId} isRunning={isRunning} setIsRunning={setIsRunning} displayPresent={displayPresent} setDisplayPresent={setDisplayPresent} bigPresentationRef={bigPresentationRef} bigVideo={bigVideo} setBigVideo={setBigVideo} setSmallVideo={setSmallVideo} />
            ) :(
            <div className="info">
              <p><b>Status: </b>{item.quote}</p>
              <p><b>Preferred Language: </b>{item.language}</p>
            </div>
            ) 
          }
          </div>
          </li>
        ))}
      </ul>  
    </div>
  );
};

export default User;