import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import url from '../context/creds'

const secretKey = 'secret-key'; // Securely store this key

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

const Login = ({ setToken, setCurrentUser, setIsLoggedIn, setMessages, socket, setUsers }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isLoggedInLocal, setIsLoggedInLocal] = useState(false); // Rename to avoid conflict

  const navigate = useNavigate();
  const decryptData = (encryptedData) => {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedString);
    } catch (error) {
      console.error('Error decrypting data:', error);
      throw new Error('Error decrypting data');
    }
  };
  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('Socket connected in Login component');
      });
    }
  }, [socket]);

  const signIn = () => {
    
    const userData = {
      username: username, // Replace with actual user ID
      password:password
    };

    const encryptedData = encryptData(userData);
    axios.post(url+'login', {encryptedData})
      .then(response => {
        const token=decryptData(response.data.token)
        const username=decryptData(response.data.username)
        const chatHistory=decryptData(response.data.chatHistory)
        localStorage.setItem('token', token);
        sessionStorage.setItem('curruser', username);
        setCurrentUser(username);
        setIsLoggedInLocal(true); // Use local state for isLoggedIn
        setToken(token);
     
        setMessages(chatHistory || []);
        if (socket) {
          socket.emit('register', username);
        }
        navigate("/home");
      })
      .catch(error => {
        alert('Error logging in');
      });
  };

  useEffect(() => {
    if (isLoggedInLocal) {
      axios.get(url+'users')
        .then(response => {
          setUsers(response.data.filter(user => user.username !== username));
         
        })
        .catch(error => {
          console.error('Error fetching users', error);
        });
    }
  }, [isLoggedInLocal, username, setUsers]);

  return (
    <div className="formContainer">
      <div className="formWrapper">
        <span className="logo">VNN Chat</span>
        <span className="title">Login</span>
        <form>
          <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type="button" onClick={signIn}>Sign In</button>
        </form>
        <p>
          Don't have an account? <Link to="/register">Register</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
