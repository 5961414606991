import React from "react";
import Navbar from "./navbar";
import Search from "./search";
//import Chats from "./chats";

const Sidebar = ({ selectChat, selectedChat, users, currUser, handleLogOut}) => {
  return (
    <div className="sidebar">
      <Navbar currentUser={currUser} handleLogOut={handleLogOut}/>
      <Search selectChat={selectChat} selectedChat={selectedChat} users={users} />
    </div>
  );
};

export default Sidebar;
