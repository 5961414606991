export const PROFILE = 
[
    {
        id: 0,
        name: "Nitin",
        image: '/images/Nitin.jpeg',
        quote: "Imagine all the eatables, living in conFusion!",
        phone: "9005458865",
        language: "English"
    }
];