import React, { useRef, useState, useEffect } from "react";
import Messages from "./messages";
import Input from "./input";
import Emojis from "./emojis";
import Presentation from "./presentation";


const Chat = ({ currentUser, selectedChat, messages, message, setMessage, showPicker, setShowPicker, sendMessage, closeChat ,userlang, displayPresent, setDisplayPresent, displayBox, setDisplayBox, isCall, setIsCall, isVideoCall, setIsVideoCall, socket, bigPresentationRef, setBigVideo, setSmallVideo  }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const [onlineUsers, setOnlineUsers] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
      timeZone: 'IST'
    });
  };
  // Emit 'getuserStatus' when a new user is selected
  useEffect(() => {
    if (selectedChat && selectedChat.username) {
      socket.emit('getuserStatus',(selectedChat.username),socket.id);
    }
    console.log(socket.id)
  }, [selectedChat,socket]);

  // Listen for 'userstatus' updates
  useEffect(() => {
    const handleUserStatus = (onlineusers) => {
     setOnlineUsers(onlineusers)
     setDataLoaded(true); 
    };


    socket.on('userstatus', handleUserStatus);

    return () => {
      socket.off('userstatus', handleUserStatus); // Clean up socket listener
    };
  }, [socket]);

  useEffect(() => {
    if (selectedChat.username !== null && onlineUsers[selectedChat.username] !== undefined) {
      setDataLoaded(true);
    } else {
      setDataLoaded(false);
    }
  }, [selectedChat.username, onlineUsers]);

  if (selectedChat.username === null) {
    return null; // or some placeholder
  }

  console.log("Chat");


  const filteredMessages = messages.filter(
    (msg) =>
      (msg.from === currentUser && msg.to === selectedChat.username) ||
      (msg.from === selectedChat.username && msg.to === currentUser)
  );

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const toggleCall = () => {
    if (!displayBox){
      setDisplayBox(true);
      setIsCall(true);
    }
    else{
      setIsCall(!isCall);
    }
  }

  const toggleVideoCall = () => {
    if (!displayBox){
      setDisplayBox(true);
      setIsVideoCall(true);
    }
    else{
      setIsVideoCall(!isVideoCall);
    }
  }

  const handleButtonClick = () => {
    setIsPopupVisible(false);
  };

  const display = () => {

    setDisplayBox(true);

    setIsPopupVisible(false);
  };

  return (
    <div className="chat">
      <div className="chatInfo">
        <div className="selectedUser">
          <img src={require("../images/Nitin.jpeg")} alt="" onClick={display} />
      {dataLoaded ? (
        onlineUsers[selectedChat.username]?.status === 'Online' ? (
          <h3 onClick={display}>
            {selectedChat.username}
            <span className="green-dot"></span>
          </h3>
        ) : (
          <h3 onClick={display}>
            {selectedChat.username}
            <span className="red-dot"></span>
            <br />
            <span className="lastseen">
              {formatDate(onlineUsers[selectedChat.username]?.lastseen)}
            </span>
          </h3>
        )
      ) : (
        <h3 onClick={display}>
          {selectedChat.username}
          <span className="red-dot"></span>
          <br />
          <span className="lastseen">last seen recently</span>
        </h3>
      )}
        </div>
        <div className="chatIcons">
          <img src={require("../images/Call.png")} alt="" onClick={toggleCall} />
          <img src={require("../images/Video.png")} alt="" onClick={toggleVideoCall} />
          <img src={require("../images/More.png")} alt="" onClick={togglePopup}/>
          <button onClick={closeChat}>Close</button>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup" ref={popupRef}>
          <button onClick={display}>Show Profile</button>
          <button onClick={handleButtonClick}>Mute Contact</button>
          <button onClick={handleButtonClick}>Clear Chat</button>
          <button onClick={handleButtonClick}>Delete Chat</button>
          <button onClick={handleButtonClick}>Block Chat</button>


          <button onClick={handleButtonClick && closeChat}>Close Chat</button>

        </div>
      )}
      { (displayPresent) ? 
      (<><Presentation displayPresent={displayPresent} setDisplayPresent={setDisplayPresent} bigPresentationRef={bigPresentationRef} setBigVideo={setBigVideo} setSmallVideo={setSmallVideo} /></>
      ) : (
      <>
      <Emojis message={message} setMessage={setMessage} showPicker={showPicker} setShowPicker={setShowPicker} />
      <Messages messages={filteredMessages} currentUser={currentUser} selectedChat={selectedChat} onlineUsers={onlineUsers} />
      <Input
        userlang={userlang}
        message={message}
        setMessage={setMessage}
        setShowPicker={setShowPicker}
        sendMessage={sendMessage}
      />
      </>
      )}
    </div>
  );
};

export default Chat;
